import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

export default function Header() {
    return(
        <div className="header">
            <div></div>
            <ul className="nav">
                <li>
                    <HashLink smooth to="/#work-content">Work</HashLink>
                </li>
                <Link to="./about"><li>About me</li></Link>
                <li>
                    <HashLink smooth to="/#contact-container">Contact</HashLink>
                </li>
            </ul>
        </div>
    ) 
}