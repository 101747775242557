import React from 'react';

export default function Contact() {

    return (
        <div className="contact-container" id="contact-container">
            <h1>CONTACT</h1>
            <div className="contact-divider"></div>
            <div className="contact-info">
                <h3>I am open for work opportunities!</h3>
                <h4>
                    Please feel free to contact me either on my <a href="https://www.linkedin.com/in/sari-susanna-ellilä-29a0a9189/" target="_blank" alt="" rel="noreferrer">LinkedIn</a> or by email: <span className="email">sari.ellila@gmail.com</span>
                </h4>
            </div>
        </div>
    );
}