import './App.css';
import About from './components/About'
import Home from './components/Home'
import Header from './components/Header'
import Contact from './components/Contact'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

function App() {
  return (
    <div className="App">
    <Router>
      <Header />
      <Routes>
  <Route path="/home" element={<Home />} />
  <Route path="/about" element={<About />} />
  <Route path="*" element={<Home />} /> {/* Default to Home */}
</Routes>
      <Contact />
    </Router>
    </div>
  );
}

export default App;
