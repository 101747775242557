import React, { useState } from 'react';
import becary from '../assets/becary.png';
import irud from '../assets/irud.png';
import jpntravel from '../assets/jpntravel.png';
import giftforlife from '../assets/giftforlife.png';
import contrast from '../assets/contrast.png';
import HorrorDBScreenGrab from '../assets/HorrorDBScreenGrab.mp4'

export default function Home() {
  const [activeView, setActiveView] = useState('WEB');

  const handleWorkView = (view) => {
    setActiveView(view);
  };


  return (
    <div className="body-container">
      <div className="homepage-title">
        <h1>SARI ELLILÄ</h1>
        <h2>Web Developer and Designer</h2>
      </div>

      <div className="homepage-skills">
        <div></div>
        <div>
          <div className="skills-subgrid">
            <div>
              <h3>HTML / CSS</h3>
              <h3>Javascript</h3>
              <h3>React</h3>
              <h3>React Native</h3>
              <h3>Node.js</h3>
            </div>
            <div className="skills-design">
              <h3>Figma</h3>
              <h3>Adobe Suite</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="homepage-work-container">
        <div className="work-content" id="work-content">
          <div>
            <h1>WORK</h1>
          </div>
          <div className="homepage-work-divider"></div>

          <div className="work-content-buttons">
        <ul>
          <li>
            <button
              onClick={() => handleWorkView('WEB')}
              className={activeView === 'WEB' ? 'active' : ''}
            >
              WEB
            </button>
          </li>
          <li>
            <button
              onClick={() => handleWorkView('MOBILE')}
              className={activeView === 'MOBILE' ? 'active' : ''}
            >
              MOBILE
            </button>
          </li>
          <li>
            <button
              onClick={() => handleWorkView('DESIGN')}
              className={activeView === 'DESIGN' ? 'active' : ''}
            >
              DESIGN
            </button>
          </li>
        </ul>
      </div>

          <h4>*Solo work and projects in which code and most of the design is by me</h4>
        </div>

        <div className="work-categories">
          {activeView === 'WEB' && (
            <div className="work-categories-content">


              <div className="mobile-content-main">
                <div>
                  <img src={becary} alt="Becary" />
                </div>

                <div className="mobile-work-categories-main-content">
                  <div className="testing">
                    <div className="mobile-content-title">
                      <h3>Becary (Overseas Study Stipend - Mentor Platform)</h3>
                      <h4>DEVELOPMENT & COLLABORATED DESIGN</h4>
                      <span className="mobile-work-categories-main-content-skills">
                        <h4>SKILLS USED: Figma, HTML, CSS, Javascript, React, Firebase</h4>
                      </span>
                    </div>

                    <div className="mobile-work-categories-type">
                      <p>Project type</p>
                      <h4>FREELANCE</h4>
                    </div>
                  </div>
                  <div>   
                    <div className="mobile-work-categories-buttons">
                      <a href="http://satisfying-cushion.surge.sh
" target="_blank" rel="noopener noreferrer">
                        <button>VIEW WEBSITE</button>
                      </a>
                      <a href="https://gitfront.io/r/sarisusanna/muipD68iyuKq/becary-app/" target="_blank" rel="noopener noreferrer" alt="">
                        <button>VIEW CODE</button>
                      </a>
                    </div>
                  </div>

                  <div className="mobile-work-categories-desc">
                    <p><strong>Project Description:</strong></p>
                    <p>Working closely with start-up Becary creating a design and website structure easy to navigate and easy on the eye. Includes a lot of complex functionality such as user profiles and booking calendars. Information is stored in Google’s Firestore - backend.</p>
                  </div>
                </div>
              </div>

              <div className="work-categories-divider"></div>

              <div className="mobile-content-main">
                <div>
                  <img src={irud} alt="Iru-D-Studio" />
                </div>

                <div className="mobile-work-categories-main-content">
                  <div className="testing">
                    <div className="mobile-content-title">
                      <h3>Iru-D-Studio (3D Modeling Agency)</h3>
                      <h4>DEVELOPMENT & COLLABORATED DESIGN</h4>
                      <span className="mobile-work-categories-main-content-skills">
                        <h4>SKILLS USED: Figma, HTML, CSS, Javascript, React</h4>
                      </span>
                    </div>

                    <div className="mobile-work-categories-type">
                      <p>Project type</p>
                      <h4>FREELANCE</h4>
                    </div>
                  </div>
                  <div>   
                    <div className="mobile-work-categories-buttons">
                      <a href="http://utopian-babies.surge.sh" target="_blank" rel="noopener noreferrer">
                        <button>VIEW WEBSITE</button>
                      </a>
                      <a href="http://utopian-babies.surge.sh" target="_blank" rel="noopener noreferrer" alt="">
                        <button>VIEW CODE</button>
                      </a>
                    </div>
                  </div>

                  <div className="mobile-work-categories-desc">
                    <p><strong>Project Description:</strong></p>
                    <p>Working with Iru-D-Studio creating a more stylish and dynamic page for them. Special points in the project are the CSS and animation aspects. *The code link will be fixed soon.</p>
                  </div>
                </div>
              </div>

              <div className="work-categories-divider"></div>

              <div className="mobile-content-main">
                <div>
                  <img src={contrast} alt="Contrast Club" />
                </div>

                <div className="mobile-work-categories-main-content">
                  <div className="testing">
                    <div className="mobile-content-title">
                      <h3>Contrast Club (Luxury Jewelry Brand)</h3>
                      <h4>DEVELOPMENT</h4>
                      <span className="mobile-work-categories-main-content-skills">
                        <h4>SKILLS USED: HTML, CSS, Javascript, React</h4>
                      </span>
                    </div>

                    <div className="mobile-work-categories-type">
                      <p>Project type</p>
                      <h4>FREELANCE</h4>
                    </div>
                  </div>
                  <div>   
                    <div className="mobile-work-categories-buttons">
                      <a href="http://wandering-nail.surge.sh" target="_blank" rel="noopener noreferrer">
                        <button>VIEW WEBSITE</button>
                      </a>
                      <a href="https://github.com/SariEllila/ContrastClub-React" target="_blank" rel="noopener noreferrer">
                        <button>VIEW CODE</button>
                      </a>
                    </div>
                  </div>

                  <div className="mobile-work-categories-desc">
                    <p><strong>Project Description:</strong></p>
                    <p>Following a set design closely to showcase a new collection of the brand. It is a simple project, but I learned about working to deliver a design determined by the customer, and they were pleased with the work.</p>
                  </div>
                </div>
              </div>

              <div className="work-categories-divider"></div>

              <div className="mobile-content-main">
                <div>
                  <img src={giftforlife} alt="Gift for Life" />
                </div>

                <div className="mobile-work-categories-main-content">
                  <div className="testing">
                    <div className="mobile-content-title">
                      <h3>Gift for Life (Online Store)</h3>
                      <h4>DESIGN & DEVELOPMENT</h4>
                      <span className="mobile-work-categories-main-content-skills">
                        <h4>SKILLS USED: Figma, HTML, CSS, Javascript, React, Node.js</h4>
                      </span>
                    </div>

                    <div className="mobile-work-categories-type">
                      <p>Project type</p>
                      <h4>SCHOOL PROJECT</h4>
                    </div>
                  </div>
                  <div>   
                    <div className="mobile-work-categories-buttons">
                      <a href="" target="_blank" rel="noopener noreferrer" alt="">
                        <button>VIEW WEBSITE</button>
                      </a>
                      <a href="https://gitfront.io/r/sarisusanna/Hop4Rb67inkL/FirstWebApp/" target="_blank" rel="noopener noreferrer">
                        <button>VIEW CODE</button>
                      </a>
                    </div>
                  </div>

                  <div className="mobile-work-categories-desc">
                    <p><strong>Project Description:</strong></p>
                    <p>An online store with product information being rendered from a MongoDB Atlas database in the backend. A payment method is being implemented with Stripe.<strong>*The website link is not functional at the moment due to renewal of deployment.</strong></p>
                  </div>
                </div>
              </div>

              <div className="work-categories-divider"></div>

              <div className="mobile-content-main">
                <div>
                  <img src={jpntravel} alt="Travel Japan" />
                </div>

                <div className="mobile-work-categories-main-content">
                  <div className="testing">
                    <div className="mobile-content-title">
                      <h3>Travel Japan (Travel Website)</h3>
                      <h4>DESIGN & DEVELOPMENT</h4>
                      <span className="mobile-work-categories-main-content-skills">
                        <h4>SKILLS USED: HTML, CSS, Javascript, React</h4>
                      </span>
                    </div>

                    <div className="mobile-work-categories-type">
                      <p>Project type</p>
                      <h4>PERSONAL PROJECT</h4>
                    </div>
                  </div>
                  <div>   
                    <div className="mobile-work-categories-buttons">
                      <a href="http://superficial-sense.surge.sh" target="_blank" rel="noopener noreferrer">
                        <button>VIEW WEBSITE</button>
                      </a>
                      <a href="https://github.com/SariEllila/TRAVEL-APP" target="_blank" rel="noopener noreferrer">
                        <button>VIEW CODE</button>
                      </a>
                    </div>
                  </div>

                  <div className="mobile-work-categories-desc">
                    <p><strong>Project Description:</strong></p>
                    <p>A webpage with information on travel destinations in Japan. It includes a weather API and a quiz to determine the most suitable destination for you.</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeView === 'MOBILE' && (
            <div className="mobile-content-main">
              <div class="testing-video">
              <video autoPlay loop muted className="w-full h-full object-cover">
           <source src={HorrorDBScreenGrab} type="video/mp4" alt="video of app"/>
           Your browser does not support the video tag.
      </video>
              </div>

              <div className="mobile-work-categories-main-content">
                <div className="testing">
                  <div className="mobile-content-title">
                    <h3>Horror Database (Movie App)</h3>
                    <h4>DESIGN & DEVELOPMENT</h4>
                    <span className="mobile-work-categories-main-content-skills">
                      <h4>SKILLS USED: HTML, CSS, Javascript, React Native, Expo</h4>
                    </span>
                  </div>

                  <div className="mobile-work-categories-type">
                    <p>Project type</p>
                    <h4>SCHOOL PROJECT</h4>
                  </div>
                </div>

                <div className="mobile-work-categories-buttons">
                  <a href="https://gitfront.io/r/sarisusanna/Rcghryp6jSeR/MovieDB/" target="_blank" rel="noopener noreferrer">
                    <button>VIEW CODE</button>
                  </a>
                </div>

                <div className="mobile-work-categories-desc">
                  <p><strong>Project Description:</strong></p>
                  <p>A movie database app specified for the horror genre. It uses TMDB Movie API to receive the film information.</p>
                </div>
              </div>
            </div>
          )}

          {activeView === 'DESIGN' && (
            <div className="design-projects-text">
              DESIGN PROJECT DETAILS COMING SOON.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}