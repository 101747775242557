import React from 'react'
import omakuva from '../assets/omakuva.jpg'
import {Link} from 'react-router-dom'

export default function About() {
    return (
        <div>
            <div class="aboutme-title">
            <h1>Hi! I'm Sari from Helsinki, Finland. I love programming because I love learning new and improving myself. I am a great teamplayer, responsible worker and very open- and positive-minded!</h1>
            </div>

            <div class="aboutme-content">
            <img src={omakuva} alt="omakuva"/>
                <div class="aboutme-text">
                        <h4>Studies and specialization</h4>
                        <p>I specialize in web applications coded with Javascript and React, but I would gladly learn other languages and libraries as well.
                        I am a graduate of Barcelona Code School's <strong>Fullstack Javascript Bootcamp</strong> and have a degree in <strong>Graphic Design</strong> from Helsinki Design School.</p>
                        <h4>Work Experience</h4>
                        <p>I have experience with multiple professional projects creating web applications. Currently I work with two companies creating website reneweals for them, writing all of the code and in most cases the design myself. I have also worked with UX / UI design projects creating data management and user profile - systems.</p>
                        <div class="aboutme-linkedin-text">
                        <p>Please read more about my background on my <a href="https://www.linkedin.com/in/sari-susanna-ellilä-29a0a9189/" target="_blank" alt="" rel="noreferrer"><span class="aboutme-linkedin">Linkedin</span></a> page</p>
                        <Link to="/home"><button>HOME</button></Link>
                        </div>
                </div>
            </div>

        </div>
    )
}